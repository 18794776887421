@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&display=swap');
/*
* @media only screen and (max-width: 599px) {...}
* @media only screen and (min-width: 600px) {...}
* @media only screen and (max-width: 900px) {...}
* @media only screen and (max-width: 1200px) {...}
*/
body{
  background: #55685D;
  font-family: 'Jost', sans-serif;
  color: #E0DADA;
  font-size: 24px;
  height: 100vh;
}

/** LOADING ANIMATION */
.animation-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.la-double-bounce {
  width: 3rem;
  height: 3rem;
  position: relative;
  
}

.la-double-bounce > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #E0DADA;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: la-bounce 2.0s infinite ease-in-out;
  animation: la-bounce 2.0s infinite ease-in-out;
}

.la-double-bounce > div:last-child {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes la-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes la-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

a:link, a:visited {
  color: #E0DADA;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.content-container{
  position: absolute;
  top: 0;
  width:  100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  -webkit-transition: 2.5s;
  -moz-transition: 2.5s;
  -o-transition: 2.5s;
  transition: 2.5s;
  visibility: hidden;
}

.fade-in{
  opacity: 1;
  visibility: visible;
}

.header-container{
  display: block;
}
.header{
  display: flex;
  justify-content: space-between;
  width:90%;
  margin-left: 5%;
  border-bottom: 1px #E0DADA solid;
}

.header h1{
  text-transform: uppercase;
  margin-block-start: 0.5em;
  margin-block-end: 0.25em;
  font-size: 1.5em;
  font-weight: 400;
}

.social-icons a{
  margin:22px 10px 10px 5px;
}

.social-icon{
  width: 40px !important;
  height: 40px !important;
}
.content-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-block{
  width: 55%;
  display:flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
  min-height: 450px;
}

.cta{
  flex-direction: row;
}
.cta-button {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	background-color:#ededed;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#55685D;
  font-family: 'Jost', sans-serif;
	font-size:0.85em;
	padding:6px 24px;
	text-decoration:none;
  text-shadow:0px 1px 0px #ffffff;
  width: 250px;
  margin-top: 25px;
  margin-bottom: 15px;
}
.cta-button:hover {
	background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
	background-color:#dfdfdf;
}
.cta-button:active {
	position:relative;
  top:1px;
}

/**MODAL*/
.backdrop{
  display:flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  padding: 50px;
}

.modal{
  display: flex;
  flex-direction: column;
  background-color: #a689a2;
  color: #373535;
  border-radius: 5px;
  max-width: 800px;
  min-height: 150px;
  padding: 0 30px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.modal a:link, .modal a:visited, .modal a:hover, .modal a:active{
  color: #373535;
}
.modal span{
  text-align: center;
  font-size: 0.85em;
}

.modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
}
.modal-header h2{
  margin-block-end: 0em;
}
.list{
  font-size: 0.75em;
}

.list li{
  margin-bottom: 0.5em;
}

.close {
  width: 32px;
  height: 32px;
  
  opacity: 0.3;
  cursor: pointer;

  border: none;
  background: none;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  display:inline-block;
  
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #373535;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.portfolio-container{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.portfolio-item{
  margin-bottom: 30px;
}

.portfolio h2{
  margin-block-end: 0;
  font-weight: normal;
}
.portfolio{
  width: 90%;
  margin-left: 5%;
  height: 100%;
  border-top: 1px #E0DADA solid; 
  display:flex;
  flex-direction: column;
}


.portfolio-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size:0.75em;
}

.portfolio-copy{
  width: 63%;
}

.portfolio-image{
  width: 30%;
  text-align: left;
}

.portfolio-image img{
  width: 100%;
}

.last-word{
  text-align: center;
  margin: 20px;
}
.footer{
  display: flex;
  align-content: center;
  justify-content: center;
  width:90%;
  margin-left: 5%;
  border-top: 1px #E0DADA solid;
  font-size: 0.85em;
  opacity: 0.75;
  max-height: 90px;
  min-height: 50px;
}

.footer a{
  margin-top: 10px;
  margin-block-end: 10px;
}

@media only screen and (max-width: 1200px) {
  .portfolio-copy{
    width: 53%;
  }
  .portfolio-image{
    width:42%;
  }
}

@media only screen and (max-width: 600px) {
  .portfolio-content{
    flex-direction: column;
  }
  .portfolio-copy{
    width: 100%;
  }
  .portfolio-image{
    width:100%;
  }

}
@media only screen and (max-width: 599px){
  .header{
    width: 94%;
    margin-left: 3%;
  }
  .header h1{
    font-size: 1em;
    margin-block-start: 0.5em;
    margin-block-end: 0;
  }

  .content-block{
    width: 90%;
  }

  .modal{
    padding: 0 15px;
  }

  .modal-header{
    min-height: 50px;
  }
  .modal-header h2{
    font-size: 1em;
    font-weight: 700;
  }

  .social-icons a{
    margin:15px 7px 10px;
  }
  
  .social-icon{
    width: 30px !important;
    height: 30px !important;
  }
}


@media only screen and (max-width: 500px){
  .social-icons a{
    margin:15px 5px 10px;
  }
  
  .social-icon{
    width: 20px !important;
    height: 20px !important;
  }
}
.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
